import { Autocomplete } from "@material-ui/lab"
import React, { useState } from "react"
import HeroSection from "../../components/HeroSection/HeroSection"
import { navigate } from "gatsby"
import useBanner from "../../hooks/useBanner"
import { Markup } from "interweave"

export default function HeroContainerThumb({ searchItems, banner, title }) {
  const [value, setValue] = useState("")
  const [desc, img] = useBanner(banner)
  const handleSubmit = () => {
    if (!value || !value?.id_sys) return
    navigate("/trattamento/", {
      state: { trattamentoId: value.id_sys, title: value.trattamento },
    })
  }

  return (
    <HeroSection page={title || null} img={img} title={"trattamenti"}>
      <HeroSection.FeatureOutlineWrapper>
        <HeroSection.FeatureOutline>
          <Markup content={desc} />
        </HeroSection.FeatureOutline>
      </HeroSection.FeatureOutlineWrapper>
      <HeroSection.BottomOverlay title="trattamenti">
        <HeroSection.BottomOverlayInner>
          <HeroSection.SearchContainer title="trattamenti">
            <Autocomplete
              freeSolo
              options={searchItems}
              getOptionLabel={option => option.trattamento}
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue)
              }}
              renderInput={params => (
                <HeroSection.SearchLeft
                  title="trattamenti"
                  {...params}
                  label="Cerca un trattamento"
                />
              )}
            />
            <HeroSection.SearchButton
              onClick={handleSubmit}
              img={require("../../images/Search.png")}
            ></HeroSection.SearchButton>
          </HeroSection.SearchContainer>
        </HeroSection.BottomOverlayInner>
      </HeroSection.BottomOverlay>
    </HeroSection>
  )
}
