import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { device } from "../../constants/breakpoints"

const Container = styled.div`
  width: 100%;
  background: #f6f6f6;
  padding: 5px;
  display: flex;
  flex-direction: row;
  padding-left: 10%;
  align-items: center;
  align-content: center;
  @media ${device.tablet} {
    display: none;
  }
`

const Text = styled.h1`
  font-size: 14px;
  color: ${({ theme }) => theme.darkblue};
  margin-top: 3px;
`

const LinkText = styled(Link)`
  font-size: 14px;
  margin-bottom: 5px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.darkblue};
    text-decoration: none !important;
  }
  color: ${({ theme }) => theme.darkblue}!important;
  font-weight: 400;
`

const Crumb = styled.h1`
  margin-top: 3px;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.blue};
`

export default function BreadCrumbs({ crumbs }) {
  if (!crumbs.length) {
    return null
  }
  return (
    <Container>
      <LinkText to={crumbs[0].origin}>Home &nbsp;</LinkText>
      {crumbs.map((c, i) => (
        <>
          {" "}
          <Text>{">"}</Text>
          {i == crumbs.length - 1 ? (
            <Crumb to={c.pathname}>&nbsp;{c.crumbLabel} &nbsp;</Crumb>
          ) : (
            <LinkText to={c.pathname}>&nbsp;{c.crumbLabel} &nbsp;</LinkText>
          )}
        </>
      ))}
    </Container>
  )
}
